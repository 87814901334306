import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';
import TopRibbon from '../../containers/Hosting/TopRibbon';

import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';

import ContactSection2 from '../../containers/Hosting/Contact2/Misc';
import MiscWrapper from './misc.style';
import { useEffect, useState } from 'react';

const MiscSection1 = ({
  name,
  sectionWrapper,
  row,
  col,
  col2,
  title,
  description,
}) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    const loc = window.location.pathname;
    if (loc.substr(loc.length - 1) === '/') {
      setUrl(loc.substring(0, loc.length - 1));
    } else {
      setUrl(loc);
    }
  }, []);
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Are you ready to kickstart your automation journey? Then look no further than our Ultimate Handbook On Hyperautomation Use Cases!"
          description="Hyperautomation is a game-changer of the future, but it can be overwhelming to know where to begin."
        />
        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="CaseStudyPage">
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            releasedClass="sticky-nav-inactive"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <MiscWrapper>
            <Box {...sectionWrapper}>
              <Container>
                <Box {...row}>
                  <Box {...col} className="text_content">
                    <Fade left cascade>
                      <Box>
                        <Heading
                          {...title}
                          content="Are you ready to kickstart your automation journey? Then look no further than our Ultimate Handbook On Hyperautomation Use Cases!"
                        />
                        <Text
                          {...description}
                          content="
                          Hyperautomation is a game-changer of the future, but it can be overwhelming to know where to begin."
                        />
                        <Text
                          {...description}
                          content="Our Ultimate Handbook On Hyperautomaiton Use Cases offers a comprehensive guide to identifying the most promising and profitable use cases specific to your industry."
                        />
                        <Text
                          {...description}
                          content="The HANDBOOK breaks down the most lucrative use cases for each industry, including financial services, hospitality, retail, and more. So what are you waiting for?"
                        />
                      </Box>
                    </Fade>
                  </Box>
                  <Box {...col}>
                    <ContactSection2 name={url} />
                  </Box>
                </Box>
              </Container>
            </Box>
          </MiscWrapper>
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

MiscSection1.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

MiscSection1.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: '100px',
    pb: ['0', '0', '50px', '50px', '50px'],
    id: 'misc_section',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49.99%', '49.99%'],
  },
  col2: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  title: {
    fontSize: ['30px', '30px', '35px', '35px', '35px'],
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '30px',
    textAlign: 'left',
    className: 'heading',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mb: '10px',
  },
};

export default MiscSection1;
